import React from 'react'

const Header = (props) => (
    <header id="header" className="alt">
        <h1>Welcome! I'm <strong>Azra Mulic.</strong></h1>
        <p> 
            I help students with all proficiency levels in both English and Bosnian languages, 
            focusing on all receptive and productive skills. 
            My goal is to present the most effective ways of learning by developing comprehensible lesson plans 
            that will stimulate and challenge students' learning styles. 
            Our online education option is unique because of the caring service we provide to each one of our students. 
            In online tutoring, we work together with an online whiteboard and audio/video chat. 
            You need just two things: a computer and a good internet connection.
        </p>
    </header>
)

export default Header
