import React from 'react'
import Helmet from 'react-helmet'
import { Waypoint } from 'react-waypoint'
import Header from '../components/Header'
import Layout from '../components/layout'
import Nav from '../components/Nav'

class Index extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      stickyNav: false,
    }
  }

  _handleWaypointEnter = () => {
    this.setState(() => ({ stickyNav: false }))
  }

  _handleWaypointLeave = () => {
    this.setState(() => ({ stickyNav: true }))
  }

  render() {
    return (
      <Layout>
        <Helmet title="Azra Mulic - Online Teacher" />

        <Header />

        <Waypoint
          onEnter={this._handleWaypointEnter}
          onLeave={this._handleWaypointLeave}
        ></Waypoint>
        <Nav sticky={this.state.stickyNav} />

        <div id="main">
          <section id="intro" className="main">
            <div className="spotlight">
              <div className="content">
                <header className="major">
                  <h2>About Me</h2>
                </header>
                <p>
                I'm an ESL teacher with 10+ years of experience. I taught ESL at public elementary school and as a Teaching Assistant at the International University of Novi Pazar.
                 I hold a Master's Degree in English Language and Literature. The goal of my personalized lesson plans is to improve your language skills, regardless if you are a native or non-native speaker.
                  The correspondence course can initially start from phonics to a more advanced curriculum. These classes can help school-age children and adults learn listening, reading, writing and conversation (critical thinking skills) in both languages. Simultaneously, we will design a personalized curriculum in pursuance of meeting students' learning styles and necessities. 
                  Among the services, we offer  Qur'an memorization guidance with all ages and levels, from learning Arabic letters pronunciation to reading with a thorough Tajweed rules application.
                </p>
              </div>
            </div>
          </section>

          <section id="first" className="main special">
            <header className="major">
              <h2>Services I offer</h2>
            </header>
            <ul className="features">
              <li>
                <span className="icon major style1 fa-language"></span>
                <h3>Language</h3>
                <p>
                  Bosnian, Serbian and ESL (English as a Secong Language) tutoring
                </p>
              </li>
              <li>
                <span className="icon major style5 fa-file-text"></span>
                <h3>Essay/Writing </h3>
                <p>
                  Essay composition <br></br>
                  Revisions and coaching <br></br>
                  Creating effective, insightful and concise essays <br></br>     
                </p>
              </li>
            </ul>
          </section>

          <section id="second" className="main special">
            <header className="major">
              <h2>Testimonials</h2>
            </header>
            <p className="features">
              "Azra is an amazing teacher. She's very patient and knowledgable. We had great success working with her."
              <br />
              Zenith K.
            </p>
          </section>

          <section id="cta" className="main special">
            <header className="major">
              <h2>Get Started</h2>
              <p>
                Schedule a free intro session by clicking on the button below
              </p>
            </header>
            <footer className="major">
              <ul className="actions">
                <li>
                  <a href="https://app.acuityscheduling.com/schedule.php?owner=18777715" className="button special">
                    Schedule Appointment
                  </a>
                </li>
              </ul>
            </footer>
          </section>
        </div>
      </Layout>
    )
  }
}

export default Index
